import { useIsSmallScreen } from 'hooks/use-is-small-screen';
import useGetParentRef, { ParentRefType } from 'hooks/use-parent-ref';
import { useSingletonTimeout } from 'hooks/use-timer';
import React from 'react';
import { createPortal } from 'react-dom';

interface PortalFooterProps {
  children: React.ReactNode;
}

export default function PortalFooter(props: PortalFooterProps) {
  const footerRef = useGetParentRef(ParentRefType.Footer);
  const [unmounted, setUnmounted] = React.useState(false);
  const small = useIsSmallScreen();

  const { setTimeout } = useSingletonTimeout();

  // local state is required so that we can re-evaluate the portal creation process after the screen changes size
  React.useEffect(() => {
    setUnmounted(true);
    setTimeout(() => setUnmounted(false), 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [small]);

  if (unmounted || !footerRef.current) {
    return null;
  }
  return createPortal(
    props.children,
    footerRef.current,
    `subscription-error-${small ? 'mobile' : 'desktop'}`,
  );
}
