import { AssetPaths } from 'common/constants/assets';
import Separator from 'components/common/separator';
import Text from 'components/elements/text/base';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import StatusViewScaffold from './scaffold';

interface Props {
  title?: React.ReactNode;
  description?: React.ReactNode;
  imageSource?: string;
  /** Offset from the header in pixels. This is because Empty View needs to fill the entire screen and that requires knowledge of the header height*/
  headerOffset?: number;
  below?: React.ReactNode;
}

export default function EmptyView(props: Props) {
  const { t } = useTranslation();
  const {
    title = t('error:no_result_found'),
    description,
    imageSource,
    headerOffset = 100,
    below,
  } = props;
  return (
    <StatusViewScaffold
      fullHeight
      title={
        <Text ta="center" textVariant="body1Semibold">
          {title}
        </Text>
      }
      description={
        <>
          <Text
            textVariant="body2Regular"
            ta="center"
            textColor="foregroundTertiary"
          >
            {description}
          </Text>
          {below && (
            <>
              <Separator gap={16} direction="vertical" />
              {below}
            </>
          )}
        </>
      }
      height={240}
      image={
        <Image
          width={233}
          height={233}
          alt={t('error:no_result_found')}
          src={imageSource ?? AssetPaths.Empty}
          onError={(event: any) => {
            event.target.id = AssetPaths.Fallback;
            event.target.srcset = AssetPaths.Fallback;
          }}
        />
      }
      customization={{
        headerOffset,
      }}
    />
  );
}
