import classNames from 'classnames';
import { AssetPaths } from 'common/constants/assets';
import Button from 'components/elements/button';
import Text from 'components/elements/text';
import { AppFooter } from 'components/widgets/footer';
import PortalFooter from 'components/widgets/footer/portal';
import { useGetNotFoundIndicator } from 'hooks/use-get-not-found-indicator';
import useKurosimNavigation, { isTabRoute } from 'hooks/use-kurosim-navigation';
import { StatusViewScaffold } from 'modules/status';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import structuralStyles from 'styles/layout.css';

import Separator from '../separator';

export interface CustomErrorViewComponentProps {
  statusCode: number;
  refetch?: () => void;
  fallback: React.ReactNode;
}
export default function CustomErrorViewComponent(
  props: CustomErrorViewComponentProps,
) {
  const { setNotFound } = useGetNotFoundIndicator();
  const { t } = useTranslation();
  const { statusCode, fallback } = props;
  const { back } = useKurosimNavigation();

  const { pathname } = useRouter();
  const isTab = React.useMemo(() => {
    return isTabRoute(pathname);
  }, [pathname]);

  const statusViewProps = React.useMemo(() => {
    switch (statusCode) {
      case 404:
        return {
          title: t('error:client_not_found_error'),
          description: t('error:client_not_found_error_description'),
          image: AssetPaths.ClientNotFoundError,
          footerLabel: t('common:go_back'),
        };
      case 500:
        return {
          title: t('error:server_error'),
          description: t('error:server_error_description'),
          image: AssetPaths.ServerError,
          footerLabel: t('common:retry'),
        };
      default:
        return undefined;
    }
  }, [statusCode, t]);

  const onClickFooter = React.useMemo(() => {
    switch (statusCode) {
      case 404:
        return () => {
          back();
        };
      default:
        return undefined;
    }
  }, [back, statusCode]);

  React.useEffect(() => {
    if (statusCode === 404) {
      setNotFound(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (typeof statusViewProps === 'undefined') return fallback;

  const footerComponent = onClickFooter ? (
    !isTab ? (
      <PortalFooter>
        <AppFooter
          mainButtonProps={{
            type: 'main',
            onClick: onClickFooter,
            children: statusViewProps.footerLabel,
            fullWidth: true,
          }}
        />
      </PortalFooter>
    ) : (
      <>
        <Separator gap={16} direction="vertical" />
        <div
          className={classNames(
            structuralStyles.padding({ horizontal: 16 }),
            structuralStyles.fill({ width: true }),
          )}
        >
          <Button onClick={onClickFooter} fullWidth>
            {statusViewProps.footerLabel}
          </Button>
        </div>
      </>
    )
  ) : undefined;

  return (
    <StatusViewScaffold
      fullHeight
      title={<Text textVariant="h2">{statusViewProps.title}</Text>}
      description={
        <>
          <Text
            textColor="foregroundTertiary"
            textVariant="body2Regular"
            ta="center"
          >
            {statusViewProps.description}
          </Text>
          {footerComponent}
        </>
      }
      image={
        <Image
          width={233}
          height={233}
          alt={statusViewProps.title}
          src={statusViewProps.image}
        />
      }
    />
  );
}
