import classNames from 'classnames';
import { CustomValue } from 'common/repositories/customization';
import { AppFooter } from 'components/widgets/footer';
import { AppHeader } from 'components/widgets/header';
import { AppLayout } from 'components/widgets/layout';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import structuralStyles from 'styles/layout.css';

import ActionStatusLottie, { ActionStatusLottieType } from './lottie';
import StatusViewScaffold from './scaffold';

interface ActionStatusViewProps {
  status: ActionStatusLottieType;
  title: React.ReactNode;
  description: React.ReactNode;
}

interface Props extends ActionStatusViewProps {
  actionName?: React.ReactNode;
  back?: boolean;
  headerTitle?: React.ReactNode;
  onDone(): void;
}

export default function ActionStatusScreen(props: Props) {
  const { onDone, actionName, status, title, description, back, headerTitle } =
    props;
  const { t } = useTranslation();
  return (
    <AppLayout
      Header={
        (!!back || !!headerTitle) && (
          <AppHeader
            back={back}
            title={headerTitle}
            allowedDefaultActions={[]}
          />
        )
      }
      Footer={
        <AppFooter
          mainButtonProps={{
            type: 'main',
            children: actionName ?? t('common:done'),
            onClick: onDone,
            fullWidth: true,
          }}
        />
      }
      customization={{
        body: {
          className: CustomValue.combine(
            structuralStyles.fill({ height: true }),
          ),
        },
        main: {
          className: CustomValue.combine(
            classNames(
              structuralStyles.fill({ height: true }),
              structuralStyles.padding({ all: 16 }),
            ),
          ),
        },
      }}
    >
      <StatusViewScaffold
        title={title}
        description={description}
        image={<ActionStatusLottie type={status} />}
        fullHeight
      />
    </AppLayout>
  );
}
